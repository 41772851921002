.experience-item {
    width: 400px;
    position: relative;
    border-radius: 2px;
    z-index: 2;
    color: black;
    background-color: white;
    border-radius: 0.25em;
    padding: 1em;
    box-shadow: 0px 0px 3px rgba(51, 51, 51, 0.286);

    &--page {
        width: 80%;
        margin: 0 auto;
    }

    &__image {
        display: block;
        width: 336px;
        height: auto;
        margin: 1em 0;
        border-radius: 0.25em;

        &--page {
            width: 60%;

            @media screen and (max-width: 600px) {
                width: 80%;
            }
        }
    }

    .banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 0.25em;
        border: 1px rgb(137, 137, 137) solid;
        padding: 1em;

        @media screen and (max-width: 600px) {
            &--page {
                padding-top: 6em;
            }
        }
    }

    .view-list-button {
        position: absolute;
        top: 3em;
        left: 3em;

        @media screen and (max-width: 600px) {
            left: calc(50% - 58.5px);
        }
    }

    .border-corner {
        height: 30px;
        width: 30px;
        position: absolute;
        background-color: white;

        &.top-left {
            top: 0;
            left: 0;
            border-top-left-radius: 0.25em;
        }
        &.top-right {
            top: 0;
            right: 0;
            border-top-right-radius: 0.25em;
        }
        &.bottom-left {
            bottom: 0;
            left: 0;
            border-bottom-left-radius: 0.25em;
        }
        &.bottom-right {
            bottom: 0;
            right: 0;
            border-bottom-right-radius: 0.25em;
        }
    }

    @media screen and (max-width: 600px) {
        width: 300px;
        padding: 1em;

        &__image {
            width: 236px;
        }

        h2 {
            text-align: center;
        }

        .hide-mobile {
            display: none;
        }
    }
}

.banner-image {
    position: absolute;
    width: 400px;
    z-index: 1;
    background-size: cover;
    opacity: 0.9;
    background-color: #cccccc;

    @media screen and (max-width: 600px) {
        width: 300px;
        height: 200px;
    }
}

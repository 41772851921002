header {
    .brand-title {
        position: relative;
        .name {
            font-size: 120px;
            display: block;
    
            @media screen and (max-width: 600px) {
                font-size: 60px;
            }
        }
        .linebreak {
            font-size: 40px;
            display: block;
            position: absolute;
            left: calc(50% - 20px);
            top: calc(50% - 30px);
    
            @media screen and (max-width: 600px) {
                font-size: 30px;
                top: calc(50% - 20px);
            }
        }
    }
    
    .hero-content {
        margin: 6em 0;
    
        .image-container {
            padding: 0.5em;
            background-color: white;
            box-shadow: 0px 1px 4px rgba(51, 51, 51, 0.26);
            margin-bottom: 4em;
        }
    }
}
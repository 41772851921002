.site-description-container {
    margin-bottom: 6em;

    p.content-text {
        font-size: 32px;
        line-height: 2;
        letter-spacing: 3px;
        margin-bottom: 1em;

        @media screen and (max-width: 600px) {
            font-size: 20px;
            line-height: 1.5;
        }
    }
}

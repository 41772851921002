$other: rgb(239, 234, 226);

.page-container {
    height: 100%;
    min-height: 100vh;
    max-width: 100vw;
    background-position: 20% 100%;
    background-size: cover;
    top: 0;
    left: 0;
    background-color: $other;
    padding-bottom: 8em;

    .page-content {
        max-width: 1140px;
        width: 100%;
        min-width: 350px;
        margin: 0 auto 0 auto;
        padding: 0 2em;
    }
}

.divider-main {
    height: 300px;
    width: 100%;
    background-color: $other;
}

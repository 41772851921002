$other: rgb(239, 234, 226);

.experience-page {
    min-height: 100vh;
    padding: 3em;
    background-size: cover;
    background-color: $other;

    .experience-container {
        padding: 3em;
    }

    .modal-card-head, .modal-card-foot {
        background-color: rgb(247, 247, 247);
    }
}

.tags-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.experience-item-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2em;
    width: fit-content;
    margin: 0 auto;

    @media screen and (max-width: 1300px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
    }
}
$lavender: rgb(133, 106, 121);
$other: rgb(239, 234, 226);
$primary: $lavender;
$warning: $other;

@import "node_modules/bulma/bulma.sass";
@import url('https://fonts.googleapis.com/css2?family=Allison&family=Eagle+Lake&family=Roboto+Flex:opsz,wght@8..144,300&display=swap');

* {
    box-sizing: border-box;
}

html {
    overflow-y: auto;
    overflow-x: auto;
}

body {
    margin: 0;
    font-family: "Roboto Flex", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.fancy {
    font-family: "Eagle Lake", cursive;
}

.real-fancy {
    font-family: "Allison", cursive;
}

.is-centered-element {
    margin: 0 auto;
    display: block;
}
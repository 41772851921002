.message-container {
    width: 100%;
    height: 100%;
    min-height: 300px;
    background-color: white;
    color: #333;
    border-radius: 0.25em;
    padding: 1em;
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

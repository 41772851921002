.management-page-container {
    background-color: #666;
    min-height: 100vh;
    padding-bottom: 10vh;

    .management-content-container {
        padding-top: 2em;
    }
}

.experience-list-container {
    max-width: 500px;
    min-width: 346px;
    width: 100%;
    margin: 0 auto;
    padding: 0 1em;
}

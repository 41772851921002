.bg-management-experience {
    background-color: white;
    border-radius: 0.25em;
}

.management-experience-container {
    width: 100%;
    background-color: white;
    padding: 1.5em 1em;
    margin-top: 1em;
    border-radius: 0.25em;

    .display-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.settings-container {
    display: none;

    &--active {
        display: block;
    }
}
.pledges-list-container {
    max-width: 500px;
    width: 100%;
    min-width: 346px;
    margin: 0 auto;
}

.joined-experience-container {
    background-color: white;
    padding: 1em;
    margin-bottom: 1em;
    border-radius: 4px;
}

.joined-experience {
    padding: 1em;
    border-radius: 4px;
    border: 1px #ccc solid;
    margin-bottom: 1em;

    &__display {
        display: grid;
        grid-template-columns: 1fr 4fr 2fr 2fr;
    }

    &__settings {
        margin-top: 1em;
    }

    p {
        font-size: 12px;
    }
}
